@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --light-color: #f8f9fa;
  --dark-color: #000;
  --header-background: var(--dark-color);
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body {
  margin: 0;
  font-family: var(--font-family-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-color);
  background-color: var(--light-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--dark-color);
  text-decoration: underline;
}

.error-message {
  color: var(--danger-color);
}

.loading-user-data {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 540px;
  }
}

@media (max-width: 575px) {
  .container {
    max-width: 100%;
    padding: 0 10px;
  }
}

.logo.landing {
  background-color: #9ff7b5;
  padding: 10px 16px 8px 16px;
  border-radius: 18px;
  path {
    fill: #000;
  }
}

.link {
  display: inline-block;
  align-items: center;
}